<template>
  <div class=''>
    安洛云综合网站
  </div>
</template>

<script>

export default {
  data () {
    return {

    };
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style scoped>
</style>